//create theme

export const lightTheme = {
    colors: {
      navbarBgColor: "white",
      mainColor: "#86C8BC",
      logoColor: "darkslategray",
      linkColor: "#8B7E74",
      detailBgColor: "#e1f1dd",
      detailColor: "rgb(5,5,5)",
      linkHoverColor: "rgb(5,5,5)",
      headerFormColor: "rgba(15, 63, 42, 0.36)",
      headerColor:"white",
      
    },

    screens: {
      lg: "992px",
    }
  };
  
  
  export const darkTheme = {
    colors: {
      navbarBgColor: "rgba(255, 255, 255, 0.46)",
      mainColor: "rgb(15,15,15)",
      logoColor: "rgb(5,5,5)",
      linkColor: "rgb(5,5,5)",
      detailBgColor: "rgb(15,15,15)",
      detailColor: "white",
      linkHoverColor: "bisque",
      headerFormColor: "rgba(255, 255, 255, 0.46)",
      headerColor:"red",
    },
    screens: {
      lg: "992px",
    },
  };
  