// footer Style

import { styled } from "styled-components";

export const FooterContainer=styled.div`
position: relative;
`

export const FooterBtn=styled.button`
    position: fixed;
    border:none;
    outline: none;
    background:none;
    cursor: pointer;
    bottom: 0px;
    padding: 1rem;

`;